<template>
	<div class="cbox">
		<div v-if="false" class="headbar">
			<div style="float: right">


				<!-- <el-button icon="el-icon-upload" style="margin-left:10px" size="small"
					@click="dialogshow1 = true">导入就业统计表</el-button>

				<el-button icon="el-icon-upload" style="margin-left:10px" size="small"
					@click="dialogShow = true">导入分类表</el-button>

					<el-button icon="el-icon-upload" style="margin-left:10px" size="small"
					@click="dialogShow3 = true">导入就业名册</el-button> -->






			</div>
			<div style="padding-top: 14px">
				<div style=" width: 200px;  margin-top:-10px;">
					<div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
						<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
					</div>
					<div style="margin-left: 50px; color:#535353; font-size:20px !important">就业情况表</div>
				</div>

			</div>
		</div>
		<div class="w_100 h_100 bcontent flexCloumn borderbox" style="padding:20px 0;">

			<!-- <el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="就业统计表" name="second"></el-tab-pane>
			</el-tabs> -->


			<div v-if="tjData&&activeName=='second'" class="w_100 h_100 ">

				<jiuyetongji class="w_100 h_100 flexCloumn"></jiuyetongji>
			</div>


		</div>


		<el-dialog class="cus_dialog " title="导入毕业生就业统计表" :visible.sync="dialogshow1" :close-on-click-modal="false"
			width="600px" height="30%">

			<div style="line-height: 50px;">
				<a href="/xls/毕业生就业统计表（需各班和系部汇总表）.docx" target="_blank">毕业生就业统计表导入模板下载（word/docx）</a>
			</div>
			<div style="line-height: 50px; color:orangered">
				请严格按照模板格式填入正确的数值。每年只需导入一次，请导入全校汇总表
			</div>
			<div style="text-align:right">


				<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
					:on-success="uploadFiles" :show-file-list="false" name="image">
					<el-button icon="el-icon-upload" type="primary" style="margin-left:100px"
						size="small">导入毕业生就业统计表</el-button>
				</el-upload>
			</div>

		</el-dialog>
		<el-dialog class="cus_dialog " title="导入毕业生分类表" :visible.sync="dialogShow" :close-on-click-modal="false"
			width="600px" height="30%">

			<div style="line-height: 50px;">
				<a href="/xls/中等职业学校毕业生分类表（需系部汇总表）.xlsx" target="_blank">毕业生分类表导入模板下载（excel/xlsx）</a>
			</div>
			<div style="line-height: 50px; color:orangered">
				请严格按照模板格式填入正确的数值。每年只需导入一次，请导入全校汇总表
			</div>

			<div style="text-align:right">
				<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
					:on-success="uploadFiles" :show-file-list="false" name="image">
					<el-button icon="el-icon-upload" type="primary" style="margin-left:100px"
						size="small">导入毕业生分类表</el-button>
				</el-upload>
			</div>
		</el-dialog>

		<el-dialog class="cus_dialog " title="就业学生名册表" :visible.sync="dialogShow3" :close-on-click-modal="false"
			width="600px" height="30%">

			<div style="line-height: 50px;">
				<a href="/xls/毕业实习审批表模板.docx" target="_blank">就业学生名册表导入模板下载（excel/xlsx）</a>
			</div>
			<div style="line-height: 50px; color:orangered">
				请严格按照模板格式填入正确的数值
			</div>

			<div style="text-align:right">
				<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
					:on-success="uploadFiles3" :show-file-list="false" name="image">
					<el-button icon="el-icon-upload" type="primary" style="margin-left:100px"
						size="small">导入就业学生名册表</el-button>
				</el-upload>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import diaochaChart from './com/diaochaChart.vue'
import diaochaFenlei from './com/diaochaFenlei.vue'
import jymingce from './com/sheet_jymingce.vue'
import jiuyetongji from './com/jiuyetongji.vue'
export default {
	components: {
		diaochaChart,diaochaFenlei,jymingce,jiuyetongji


	},
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			dialogShow3:false,
			activeName:"second",
			tjData:null
		}
	},
	mounted() {
			this.getDataList()

	},
	methods: {
		handleClick(){
			if(this.activeName=="second"){
				this.$nextTick(e=>{
					$("table","#tableContent").addClass("dttable")
				})
			}
		},
		getDataList() {
			
			this.$http.post("/api/get_jiuye_tjtable2").then(res => {
				if(!res.data){
					this.$message.error("还没有上传统计表")
				}
				this.tjData = res.data


				this.handleClick()
				
			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.xunfang = e

			this.dialogShow = true
		},
		uploadFiles(e) {
			if (e.src.indexOf("doc") > 0) {

				this.$http.post("/api/import_jiuyetj_word", {
					url: e.src
				}).then(res => {

					
					this.$message.success("导入成功")
					if (res.data.msg&&res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogshow1 = false
					this.activeName="second"
					this.getDataList()
					setTimeout(() => {
						this.handleClick()
					}, 500);



				})

			} else {
				this.$http.post("/api/import_jiuyefl_xls", {
					url: e.src
				}).then(res => {

					this.getDataList()
					this.$message.success("导入成功")
					if (res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg['巡访学生'] + ":" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogShow = false
					this.activeName="none"
					this.$nextTick(e=>{
						this.activeName="third"
					})


				})
			}
		},
		uploadFiles3(e) {
			if (e.src.indexOf("xls") > 0) {

				this.$http.post("/api/import_jiuyemingche_xls", {
					url: e.src
				}).then(res => {

					if (res.data.msg&&res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}else{
						this.$message.success("导入成功")
					}
					
					this.dialogShow3= false
					this.activeName=""
					this.$nextTick(e=>{
						this.activeName="third"
					})
					
				})

			} else {
				this.$message.error("请上传docx格式文件")
			}
		},

	}
}
</script>
<style scoped></style>
