<template>
	<div>
		<div class="w_100 headbar flexEnd borderbox" style="padding:0 10px 0 0;border:0;">
			<div style="">


				<el-button icon="el-icon-download" style="margin-left:10px" size="small" type="text" title="请先选择班级"
					@click="downTongji">下载就业情况表</el-button>

        <el-button v-if="$store.state.pmid == 1" icon="el-icon-download" style="margin-left:10px" size="small" type="text" title="全部学校"
           @click="downloadAll">下载全部学校</el-button>

			</div>
			<el-button class="ml20" @click="initFullTables" size="middle" type="text" title="全屏展示表格，也可双击表格全屏展示"> <i
					class="el-icon-full-screen" style="font-size:24px!important;"></i></el-button>
		</div>
		<div class="w_100 h_100 bcontent" id="myFullTable" @dblclick="initFullTables" style="background-color: #fff;">
			<div v-if="DataInfo" class="w_100" style="margin:0 auto; position: relative;">

				<div style="font-size:25px !important;font-weight: bold; line-height: 50px; text-align: center;">
					{{
					year }}年南京市中等职业学校毕业生就业情况表</div>
				<div>
					<div style="padding-bottom:10px">
						<div style="display: inline-block;width: 33%;">
							学校：{{ DataInfo.platform }}
						</div>
						<div style="display: inline-block;width: 33%;">
							学制：<el-radio-group v-model="xuezhi" @change="getData">
								<el-radio label="" v-if="DataInfo.xuezhi.length > 1">全部</el-radio>
								<el-radio :label="item.class_xz" v-for="(item, idx) in DataInfo.xuezhi" :key="idx">{{
									item.class_xz }}</el-radio>

							</el-radio-group>
						</div>
					</div>
					<table class="dttable">
						<tr>
							<td rowspan="2">毕业生数</td>
							<td rowspan="2">就业人数</td>
							<td rowspan="2">直接就业人数</td>
							<td rowspan="2">就业率</td>
							<td rowspan="2">对口就业率</td>
							<td colspan="2">就业学生中：<br />就业去向分组（一）</td>
							<td colspan="2">直接就业学生中：<br />
								就业产业分组（二）</td>
							<td colspan="2">直接就业学生中：<br />
								就业地域分组（三）</td>
							<td colspan="2">直接就业学生中：<br />就业地点分组（四）</td>
							<td colspan="2">直接就业学生中：<br />
								就业渠道分组（五）</td>


						</tr>

						<tr>
							<td>去向</td>
							<td>人数</td>
							<td>去向</td>
							<td>人数</td>
							<td>去向</td>
							<td>人数</td>
							<td>地点</td>
							<td>人数</td>
							<td>渠道</td>
							<td>人数</td>
						</tr>

						<tr>
							<td rowspan="4">{{ DataInfo.bys_num }}</td>
							<td rowspan="4">{{ DataInfo.jiuye_num }}</td>
							<td rowspan="4">{{ DataInfo.zhijie_num }}</td>
							<td rowspan="4">{{ parseInt(DataInfo.jiuye_num /
								DataInfo.bys_num * 10000) / 100 }}%</td>
							<td rowspan="4">{{ parseInt(DataInfo.duikou_num /
                (DataInfo.bys_num - DataInfo.daiye_num) * 10000) / 100 }}%</td>
							<td>机关和企事业单位</td>
							<td id="jghqsydw"><span v-for="(item, i) in DataInfo.quxiang" :key="i"
									v-if="item.quxiang == '机关和企事业单位'">{{
									item.n }}</span></td>
							<td>第一产业</td>
							<td id="dycy"><span v-for="(item, i) in DataInfo.chanye" :key="i"
									v-if="item.chanye == '第一产业'">{{ item.n
									}}</span></td>
							<td>本地</td>
							<td id="bd"><span v-for="(item, i) in DataInfo.diyu" :key="i" v-if="item.diyu == '本地'">{{
									item.n
									}}</span>
							</td>
							<td>城区</td>
							<td id="cq"><span v-for="(item, i) in DataInfo.didian" :key="i"
									v-if="item.didian == '城区'">{{
									item.n
									}}</span></td>
							<td>学校推荐</td>
							<td id="xxtj"><span v-for="(item, i) in DataInfo.qudao" :key="i"
									v-if="item.qudao == '学校推荐'">{{
									item.n
									}}</span></td>
						</tr>

						<tr>

							<td>合法从事个体经营</td>
							<td id="hfcsgtjy"><span v-for="(item, i) in DataInfo.quxiang" :key="i"
									v-if="item.quxiang == '合法从事个体经营'">{{
									item.n }}</span></td>
							<td>第二产业</td>
							<td id="decy"><span v-for="(item, i) in DataInfo.chanye" :key="i"
									v-if="item.chanye == '第二产业'">{{ item.n
									}}</span></td>
							<td>异地</td>
							<td id="yd"><span v-for="(item, i) in DataInfo.diyu" :key="i" v-if="item.diyu == '异地'">{{
									item.n
									}}</span>
							</td>
							<td>镇区</td>
							<td id="zq"><span v-for="(item, i) in DataInfo.didian" :key="i"
									v-if="item.didian == '镇区'">{{
									item.n
									}}</span></td>
							<td>自联</td>
							<td id="zl"><span v-for="(item, i) in DataInfo.qudao" :key="i" v-if="item.qudao == '自联'">{{
									item.n
									}}</span></td>
						</tr>
						<tr>

							<td>其他方式</td>
							<td id="qtfs"><span v-for="(item, i) in DataInfo.quxiang" :key="i"
									v-if="item.quxiang == '其他方式'">{{ item.n
									}}</span></td>
							<td>第三产业</td>
							<td id="dscy"><span v-for="(item, i) in DataInfo.chanye" :key="i"
									v-if="item.chanye == '第三产业'">{{ item.n
									}}</span></td>
							<td>境外</td>
							<td id="jw"><span v-for="(item, i) in DataInfo.diyu" :key="i" v-if="item.diyu == '境外'">{{
									item.n
									}}</span>
							</td>
							<td>乡村</td>
							<td id="xc"><span v-for="(item, i) in DataInfo.didian" :key="i"
									v-if="item.didian == '乡村'">{{
									item.n
									}}</span></td>
							<td>其他渠道</td>
							<td id="qtqd"><span v-for="(item, i) in DataInfo.qudao" :key="i"
									v-if="item.qudao == '其他渠道'">{{
									item.n
									}}</span></td>
						</tr>
						<tr>

							<td>升入高一级学校</td>
							<td colspan="2" id="srgyjxx"><span v-for="(item, i) in DataInfo.quxiang" :key="i"
									v-if="item.quxiang == '升入高一级学校'">{{ item.n
									}}</span>
							</td>

							<td colspan="2" id="srgyjxx2">
								其中升入专科学生人数
							</td>
							<td>
								{{ DataInfo.zhuanke.n }}
							</td>
							<td colspan="2" id="srgyjxx2">
								升入本科学生人数
							</td>
							<td colspan="2">
								{{ DataInfo.benke.n }}
							</td>

						</tr>



					</table>


					<table class="dttable" style="margin-top:20px">
						<tr>
							<td>专业类别</td>
							<td>毕业生数</td>
							<td>就业人数</td>
							<td>对口就业人数</td>
							<td>专业类别</td>
							<td>毕业生数</td>
							<td>就业人数</td>
							<td>对口就业人数</td>
						</tr>
						<tr v-for="(row,index) in majorTypes" :key="index">
							<template v-for="majorType in row">
                <td>{{ majorType.type_name }}</td>
                <td :id="`${majorType.type_code}1`"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
                                      v-if="item.major_type == majorType.type_name">{{
                    item.bys_num }}</span></td>
                <td :id="`${majorType.type_code}2`"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
                                      v-if="item.major_type == majorType.type_name">{{
                    item.jiuye_num }}</span></td>
                <td :id="`${majorType.type_code}3`"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
                                      v-if="item.zhuanye_type == majorType.type_name">{{
                    item.n }}</span></td>
              </template>
<!--							<td>休闲保健类</td>
							<td id="xxbjl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '休闲保健类'">{{
									item.bys_num }}</span></td>
							<td id="xxbjl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '休闲保健类'">{{
									item.jiuye_num }}</span></td>
							<td id="xxbjl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '休闲保健类'">{{
									item.n }}</span></td>-->
						</tr>
<!--						<tr>
							<td>资源环境类</td>
							<td id="zyhjl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '资源环境类'">{{
									item.bys_num }}</span></td>
							<td id="zyhjl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '资源环境类'">{{
									item.jiuye_num }}</span></td>
							<td id="zyhjl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '资源环境类'">{{
									item.n }}</span></td>
							<td>财经商贸类</td>
							<td id="cjsml1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '财经商贸类'">{{
									item.bys_num }}</span></td>
							<td id="cjsml2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '财经商贸类'">{{
									item.jiuye_num }}</span></td>
							<td id="cjsml3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '财经商贸类'">{{
									item.n }}</span></td>
						</tr>
						<tr>
							<td>能源与新能源类</td>
							<td id="nyyxnyl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '能源与新能源类'">{{
									item.bys_num }}</span></td>
							<td id="nyyxnyl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '能源与新能源类'">{{
									item.jiuye_num }}</span></td>
							<td id="nyyxnyl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '能源与新能源类'">{{ item.n
									}}</span></td>
							<td>旅游服务类</td>
							<td id="lyful1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '旅游服务类'">{{
									item.bys_num }}</span></td>
							<td id="lyful2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '旅游服务类'">{{
									item.jiuye_num }}</span></td>
							<td id="lyful3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '旅游服务类'">{{
									item.n }}</span></td>
						</tr>
						<tr>
							<td>土木水利类</td>
							<td id="tmsll1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '土木水利类'">{{
									item.bys_num }}</span></td>
							<td id="tmsll2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '土木水利类'">{{
									item.jiuye_num }}</span></td>
							<td id="tmsll3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '土木水利类'">{{
									item.n }}</span></td>
							<td>文化艺术类</td>
							<td id="whysl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '文化艺术类'">{{
									item.bys_num }}</span></td>
							<td id="whysl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '文化艺术类'">{{
									item.jiuye_num }}</span></td>
							<td id="whysl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '文化艺术类'">{{
									item.n }}</span></td>
						</tr>
						<tr>
							<td>加工制造类</td>
							<td id="jgzzl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '加工制造类'">{{
									item.bys_num }}</span></td>
							<td id="jgzzl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '加工制造类'">{{
									item.jiuye_num }}</span></td>
							<td id="jgzzl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '加工制造类'">{{
									item.n }}</span></td>
							<td>体育与健身类</td>
							<td id="tyyjsl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '体育与健身类'">{{
									item.bys_num }}</span></td>
							<td id="tyyjsl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '体育与健身类'">{{
									item.jiuye_num }}</span></td>
							<td id="tyyjsl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '体育与健身类'">{{
									item.n }}</span></td>
						</tr>
						<tr>
							<td>石油化工类</td>
							<td id="syhgl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '石油化工类'">{{
									item.bys_num }}</span></td>
							<td id="syhgl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '石油化工类'">{{
									item.jiuye_num }}</span></td>
							<td id="syhgl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '石油化工类'">{{
									item.n }}</span></td>
							<td>教育类</td>
							<td id="jyl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '教育类'">{{
									item.bys_num }}</span></td>
							<td id="jyl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '教育类'">{{
									item.jiuye_num }}</span></td>
							<td id="jyl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '教育类'">{{
									item.n }}</span></td>
						</tr>
						<tr>
							<td>轻纺食品类</td>
							<td id="qfspl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '轻纺食品类'">{{
									item.bys_num }}</span></td>
							<td id="qfspl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '轻纺食品类'">{{
									item.jiuye_num }}</span></td>
							<td id="qfspl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '轻纺食品类'">{{
									item.n }}</span></td>
							<td>司法服务类</td>
							<td id="sffwl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '司法服务类'">{{
									item.bys_num }}</span></td>
							<td id="sffwl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '司法服务类'">{{
									item.jiuye_num }}</span></td>
							<td id="sffwl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '司法服务类'">{{
									item.n }}</span></td>
						</tr>
						<tr>
							<td>交通运输类</td>
							<td id="jtysl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '交通运输类'">{{
									item.bys_num }}</span></td>
							<td id="jtysl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '交通运输类'">{{
									item.jiuye_num }}</span></td>
							<td id="jtysl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '交通运输类'">{{
									item.n }}</span></td>
							<td>公共管理与服务类</td>
							<td id="ggglyfwl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '公共管理与服务类'">{{
									item.bys_num }}</span></td>
							<td id="ggglyfwl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '公共管理与服务类'">{{
									item.jiuye_num }}</span></td>
							<td id="ggglyfwl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '公共管理与服务类'">{{ item.n
									}}</span></td>
						</tr>
						<tr>
							<td>信息技术类</td>
							<td id="xxjsl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '信息技术类'">{{
									item.bys_num }}</span></td>
							<td id="xxjsl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '信息技术类'">{{
									item.jiuye_num }}</span></td>
							<td id="xxjsl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '信息技术类'">{{
									item.n }}</span></td>
							<td>其他</td>
							<td id="qt1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '其他'">{{
									item.bys_num }}</span></td>
							<td id="qt2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '其他'">{{
									item.jiuye_num }}</span></td>
							<td id="qt3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '其他'">{{
									item.n }}</span></td>
						</tr>
						<tr>
							<td>医药卫生类</td>
							<td id="yywsl1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '医药卫生类'">{{
									item.bys_num }}</span></td>
							<td id="yywsl2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '医药卫生类'">{{
									item.jiuye_num }}</span></td>
							<td id="yywsl3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '医药卫生类'">{{
									item.n }}</span></td>
							<td>总计</td>
							<td id="zj1"><span v-for="(item, i) in DataInfo.majors_bys" :key="i"
									v-if="item.major_type == '总计'">{{
									item.bys_num }}</span></td>
							<td id="zj2"><span v-for="(item, i) in DataInfo.majors_jiuye" :key="i"
									v-if="item.major_type == '总计'">{{
									item.jiuye_num }}</span></td>
							<td id="zj3"><span v-for="(item, i) in DataInfo.majors_duikou" :key="i"
									v-if="item.zhuanye_type == '总计'">{{
									item.n }}</span></td>
						</tr>-->


					</table>

					<div style="margin-top:20px">直接就业学生中：</div>
					<table class="dttable">
						<tr>
							<td colspan="5">就业合同签订情况（人数）</td>
							<td colspan="6">起薪情况</td>
							<td colspan="5">社会保险情况（人数）</td>
						</tr>

						<tr>
							<td rowspan=2>未签合同</td>
							<td rowspan=2>1年及以内</td>
							<td rowspan=2>1～2(含)年</td>
							<td rowspan=2>2～3(含)年</td>
							<td rowspan=2>3年以上</td>
							<td rowspan=2>平均起薪(元/月)</td>
							<td colspan="5">其中：（人数）</td>
							<td rowspan=2>没有社保</td>
							<td rowspan=2>三险</td>
							<td rowspan=2>五险</td>
							<td rowspan=2>三险一金</td>
							<td rowspan=2>五险一金</td>
						</tr>

						<tr>
							<td>2490及以下</td>
							<td>2490～4000</td>
							<td>4000～5000</td>
							<td>5000～6000</td>
							<td>6000以上</td>
						</tr>

						<tr>
							<td id="wqht">
								<span v-for="(item, i) in DataInfo.hetong" :key="i" v-if="item.hetong == '未签合同'">{{
									item.n
									}}</span>
							</td>
							<td id="ynjyn">
								<span v-for="(item, i) in DataInfo.hetong" :key="i" v-if="item.hetong == '1年及以内'">{{
									item.n
									}}</span>
							</td>
							<td id="yzln">
								<span v-for="(item, i) in DataInfo.hetong" :key="i" v-if="item.hetong == '1～2(含)年'">{{
									item.n
									}}</span>
							</td>
							<td id="lzsn">
								<span v-for="(item, i) in DataInfo.hetong" :key="i" v-if="item.hetong == '2～3(含)年'">{{
									item.n
									}}</span>
							</td>
							<td id="snys">
								<span v-for="(item, i) in DataInfo.hetong" :key="i" v-if="item.hetong == '3年以上'">{{
									item.n
									}}</span>
							</td>
							<td id="pjqx">
								{{ DataInfo.salary_avg }}
							</td>
							<td id="elel">
								<span v-for="(item, i) in DataInfo.salary" :key="i" v-if="item.salary == '2490及以下'">{{
									item.n
									}}</span>
							</td>
							<td id="swjs">
								<span v-for="(item, i) in DataInfo.salary" :key="i" v-if="item.salary == '2490～4000'">{{
									item.n
									}}</span>
							</td>
							<td id="sk">
								<span v-for="(item, i) in DataInfo.salary" :key="i" v-if="item.salary == '4000～5000'">{{
									item.n
									}}</span>
							</td>
							<td id="wk">
								<span v-for="(item, i) in DataInfo.salary" :key="i" v-if="item.salary == '5000～6000'">{{
									item.n
									}}</span>
							</td>
							<td id="wkys">
								<span v-for="(item, i) in DataInfo.salary" :key="i" v-if="item.salary == '6000以上'">{{
									item.n
									}}</span>
							</td>

							<td id="mysb">
								<span v-for="(item, i) in DataInfo.baoxian" :key="i" v-if="item.wuxian == '没有社保'">{{
									item.n
									}}</span>
							</td>
							<td id="sx">
								<span v-for="(item, i) in DataInfo.baoxian" :key="i" v-if="item.wuxian == '三险'">{{
									item.n
									}}</span>
							</td>
							<td id="wx">
								<span v-for="(item, i) in DataInfo.baoxian" :key="i" v-if="item.wuxian == '五险'">{{
									item.n
									}}</span>
							</td>
							<td id="sxyj">
								<span v-for="(item, i) in DataInfo.baoxian" :key="i" v-if="item.wuxian == '三险一金'">{{
									item.n
									}}</span>
							</td>
							<td id="wxyj">
								<span v-for="(item, i) in DataInfo.baoxian" :key="i" v-if="item.wuxian == '五险一金'">{{
									item.n
									}}</span>
							</td>


						</tr>

					</table>


					<div style="margin-top:20px">毕业学生中：</div>
					<table class="dttable">
						<tr>
							<td colspan="2">资格证书情况（人数）</td>
							<td colspan="6">就业满意度情况（人数）</td>
						</tr>



						<tr>
							<td>取得职业资格证书</td>
							<td>未取得职业资格证书</td>
							<td>无法评估</td>
							<td>不满意</td>
							<td>比较满意</td>
							<td>满意</td>
							<td>非常满意</td>
						</tr>

						<tr>
							<td id="qdzyzgzs">
								<span v-for="(item, i) in DataInfo.zhengshu" :key="i"
									v-if="item.zhengshu == '取得职业资格证书'">{{
									item.n }}</span>
							</td>
							<td id="wqdzyzgzs">
								<span v-for="(item, i) in DataInfo.zhengshu" :key="i"
									v-if="item.zhengshu == '未取得职业资格证书' || !item.zhengshu">{{
									item.n }}</span>
							</td>
							<td id="wfpg">
								<span v-for="(item, i) in DataInfo.manyidu" :key="i" v-if="item.manyidu == '无法评估'">{{
									item.n
									}}</span>
							</td>
							<td id="bmy">
								<span v-for="(item, i) in DataInfo.manyidu" :key="i" v-if="item.manyidu == '不满意'">{{
									item.n
									}}</span>
							</td>
							<td id="bjmy">
								<span v-for="(item, i) in DataInfo.manyidu" :key="i" v-if="item.manyidu == '比较满意'">{{
									item.n
									}}</span>
							</td>
							<td id="my">
								<span v-for="(item, i) in DataInfo.manyidu" :key="i" v-if="item.manyidu == '满意'">{{
									item.n
									}}</span>
							</td>
							<td id="fcmy">
								<span v-for="(item, i) in DataInfo.manyidu" :key="i" v-if="item.manyidu == '非常满意'">{{
									item.n
									}}</span>
							</td>


						</tr>

					</table>


					<div style="height:50px"></div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { exportWord } from "@/utils/exportword";
export default {
    props: ["tpmid"],
    components: {

    },
    data() {
        return {
            DataInfo: null,
            year: sessionStorage.getItem("year"),
            xuezhi: "",
            majorTypes: [],
        }
    },
    mounted() {
        this.getData()
        this.getMajorType()
    },
    methods: {
        getData() {
            let data = { tpmid: this.tpmid, xuezhi: this.xuezhi }
            this.$http.post("/api/get_jiuye_tjtable2", data).then(res => {
                this.DataInfo = res.data
                if (!this.xuezhi) {
                  if (res.data.xuezhi.length == 1) {
                    this.xuezhi = res.data.xuezhi[0].class_xz
                  } else {
                    this.xuezhi = ""
                  }
                }
            })
        },
        downTongji() {
            let DataInfo = { ...this.DataInfo };
            if (this.xuezhi == "") {
                DataInfo.xuezhi5 = true
                DataInfo.xuezhi3 = true
            } else if (this.xuezhi == "高职") {
                DataInfo.xuezhi5 = true
                DataInfo.xuezhi3 = false
            } else if (this.xuezhi == "中职") {
                DataInfo.xuezhi5 = false
                DataInfo.xuezhi3 = true
            }
            DataInfo.benke = DataInfo.benke.n
            DataInfo.zhuanke = DataInfo.zhuanke.n
            DataInfo.jiuyelv = parseInt(DataInfo.jiuye_num / DataInfo.bys_num * 10000) / 100 + "%"
            DataInfo.duikoulv = parseInt(DataInfo.duikou_num / (DataInfo.bys_num - DataInfo.daiye_num) * 10000) / 100 + "%"
            $("td", ".dttable").each((idx, ele) => {
                if ($(ele).attr("id")) {
                    DataInfo[$(ele).attr("id")] = $(ele).text()
                }
            })
           
            /*if (this.xuezhi == "高职"){
                exportWord('./xls/毕业生就业统计表（高职）.docx', DataInfo, `${DataInfo.platform}毕业生就业统计表`)
            }else{
                exportWord('./xls/毕业生就业统计表（中职）.docx', DataInfo, `${DataInfo.platform}毕业生就业统计表`)
            }*/
            exportWord('./xls/毕业生就业统计表（高职）.docx', DataInfo, `${DataInfo.platform}毕业生就业统计表`)
            
        },
		downloadAll() {
          this.$http.post('/api/get_all_jiuyetj_list').then(res => {
            window.open(res.data.url)
          })
        },
      getMajorType() {
          this.$http.post('/api/getMajorType').then(res => {
            if (res.data && res.data.length) {
              let majorTypes = res.data
              majorTypes.push({id:99,type_code:'zj',type_name: '总计'})
              let count = Math.ceil(majorTypes.length / 2)
              let newData = []
              for (let i = 1; i <= count; i++) {
                newData.push(majorTypes.splice(0,2))
              }
              this.majorTypes = newData
            }
          })
      },
    }

}

</script>

<style scoped>.dttable td {
    text-align: center;
}</style>